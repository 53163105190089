import { Controller } from '@hotwired/stimulus'
import bbox from '@turf/bbox'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    center: String,
    challengeCityId: Number,
    challengeDescription: String,
    challengeEndAt: String,
    challengeStartAt: String,
    geoJsonUrl: String,
    userId: String
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }
    this.currentYear = new Date().getFullYear()
    outlet.adjustClassesRegular()

    if (outlet.mapIsInitialized) {
      this.addCity()
      this.filterLifeMap()
      this.setHalloweenMap()
    } else {
      outlet.map.once('style.load', () => {
        this.addCity()
        this.filterLifeMap()
        this.setHalloweenMap()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    if (outlet.map.getLayer('CityStrides-cityBorder')) {
      outlet.removeLayerAndSource('CityStrides-cityBorder')
      outlet.removeLayerAndSource('CityStrides-cityPin')
    }

    if (outlet.map.getLayer('CityStrides-selfLifeMap')) {
      outlet.map.setFilter('CityStrides-selfLifeMap', null)
      outlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'none')
      outlet.toggleLifeMapControl.buttonUnhighlight()
    }

    this.unsetHalloweenMap()
  }

  addCity () {
    this.mapOutlet.map.resize()

    if (this.challengeCityIdValue === '' || this.challengeCityIdValue === 0) {
      this.mapOutlet.map.jumpTo({ center: [29, 29], zoom: 1.1 })
      return
    }

    // duplicate code in cities_controller.js & map_city_controller.js
    window.fetch(this.geoJsonUrlValue, { credentials: 'same-origin' }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json()
      } else {
        throw Error('😱 There was an error when retrieving the city border')
      }
    }).then(data => {
      const boundingBox = bbox(data)
      this.mapOutlet.map.addSource('CityStrides-cityBorder', { type: 'geojson', data })
      this.mapOutlet.map.addLayer({
        id: 'CityStrides-cityBorder',
        type: 'line',
        source: 'CityStrides-cityBorder',
        paint: {
          'line-color': '#60a5fa',
          'line-width': 5
        }
      }, this.mapOutlet.map.getLayer('CityStrides-selfLifeMap') ? 'CityStrides-selfLifeMap' : 'road-label')
      this.mapOutlet.map.fitBounds(boundingBox, { animate: false, preload: true, padding: 40 })
    }).catch(error => {
      this.mapOutlet.map.jumpTo({ center: [0, 0], zoom: 10 })
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
    // new mapboxgl.Popup({ className: `cityPopup${id}` }).setLngLat(this.centerValue).setHTML(`${name}, ${regionName}`).addTo(this.mapOutlet.map)

    this.mapOutlet.map.addSource('CityStrides-cityPin', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {
          description: this.challengeDescriptionValue
        },
        geometry: {
          type: 'Point',
          coordinates: this.centerValue
        }
      }
    })
    this.mapOutlet.map.addLayer({
      id: 'CityStrides-cityPin',
      type: 'circle',
      source: 'CityStrides-cityPin',
      paint: {
        'circle-color': '#60a5fa',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#4c1d95',
        'circle-radius': 8
      }
    })
  }

  filterLifeMap () {
    if (this.challengeStartAtValue === '') {
      return
    }

    if (this.mapOutlet.map.getLayer('CityStrides-selfLifeMap')) {
      this.mapOutlet.map.setFilter(
        'CityStrides-selfLifeMap',
        [
          'all',
          ['>=', 'start_time', Date.parse(`${this.challengeStartAtValue}T00:00:00`)],
          ['<=', 'start_time', Date.parse(`${this.challengeEndAtValue}T23:59:59`)]
        ]
      )
      this.mapOutlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'visible')
      this.mapOutlet.toggleLifeMapControl.buttonHighlight()
    }
  }

  setHalloweenMap () {
    if (!(this.challengeStartAtValue === `${this.currentYear}-10-31` && this.challengeEndAtValue === `${this.currentYear}-10-31`)) {
      return
    }

    this.mapOutlet.mapStyleValue = this.mapOutlet.halloweenStyleLink
    this.mapOutlet.changeMapStyle()
  }

  unsetHalloweenMap () {
    if (!(this.challengeStartAtValue === `${this.currentYear}-10-31` && this.challengeEndAtValue === `${this.currentYear}-10-31`)) {
      return
    }

    this.mapOutlet.mapStyleValue = this.mapOutlet.regularStyleLink
    this.mapOutlet.changeMapStyle()
  }
}

import { Controller } from '@hotwired/stimulus'
import { StreamActions } from '@hotwired/turbo'
import mapboxgl from 'mapbox-gl'
import '../src/compare.js'
import along from '@turf/along'
import bbox from '@turf/bbox'
import { lineString, multiLineString } from '@turf/helpers'
import { FetchRequest } from '@rails/request.js'
import length from '@turf/length'
import { polylineToGeoJSON } from '@placemarkio/polyline'
import FullScreenControl from '../src/mapbox/full_screen.js'
import RouteBuilderControl from '../src/mapbox/route_builder.js'
import NodeHunterControl from '../src/mapbox/node_hunter.js'
import ToggleCityMapControl from '../src/mapbox/toggle_citymap.js'
import ToggleLifeMapControl from '../src/mapbox/toggle_lifemap.js'
import ToggleStyleControl from '../src/mapbox/toggle_style.js'

export default class extends Controller {
  static targets = ['routeBuild', 'routeDistance', 'routeIconLine', 'routeIconRoute', 'routeName', 'routeSave', 'routeShare', 'routeShareDiv', 'routeVisit']
  static values = {
    currentlyDrawing: Boolean,
    mapStyle: String,
    routeBuilderDisabled: Boolean,
    routeClicks: Array,
    routeDirections: Array,
    routeDistance: String,
    routeGeojson: String,
    routeId: String,
    routePreviousManeuversCount: Number,
    show: Boolean,
    supporter: Boolean,
    token: String,
    unit: String,
    userId: String
  }

  initialize () {
    this.mapIsInitialized = false
    this.darkStyleLink = 'mapbox://styles/citystrides/clq4gadad019x01p682m3b56p'
    this.halloweenStyleLink = 'mapbox://styles/citystrides/clmqkhvy404u201p78a136os3'
    this.regularStyleLink = 'mapbox://styles/citystrides/clq2mo7zv015901qu9fixf5iw'
    this.satelliteStyleLink = 'mapbox://styles/mapbox/satellite-streets-v12'
    this.mapboxNodeHunterPopup = new mapboxgl.Popup({ closeButton: false })

    if (typeof this.map === 'undefined' && this.showValue === true) {
      this.initializeTheMap()
    }
  }

  connect () {
    document.addEventListener('turbo:load', () => {
      if (this.currentlyDrawingValue === true && this.routeBuildTarget.classList.contains('hidden') && !document.getElementById('map').classList.contains('hidden')) {
        this.routeBuildTarget.classList.remove('hidden')
      }
    }, { once: true })
  }

  disconnect () {
    // purposefully doing nothing to retain the state of `this.map`
  }

  initializeTheMap () {
    if (mapboxgl.supported() === false) {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: '<p>Your browser cannot display the map. Please ensure WebGL2 is enabled or try a different browser.</p><p class="mt-2"><a href="https://get.webgl.org/webgl2/" class="button">Check Here</a></p>', type: 'error' }
      })
    }

    this.routeMileMarkers = []
    mapboxgl.accessToken = this.tokenValue
    const initialMapStyle = document.documentElement.classList.contains('dark') ? this.darkStyleLink : this.regularStyleLink
    // change localhost to IP for mobile testing
    const arrowHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://citystrides.com'
    this.map = new mapboxgl.Map({
      center: [29, 29],
      container: 'map',
      minZoom: 1,
      projection: 'mercator',
      zoom: 1.1,
      style: initialMapStyle
    })
    this.map.setMaxPitch(0)
    this.map.setMinPitch(0)

    this.map.once('style.load', () => {
      this.addButtons()
      this.addRouteBuilderLayer()
      this.map.loadImage(`${arrowHost}/arrow.png`, (error, image) => {
        if (error) {
          console.log(error)
        } else {
          this.map.addImage('arrow', image)
        }
      })
      this.mapIsInitialized = true
    })

    this.lifeMapPopup = new mapboxgl.Popup({ closeButton: false })
    this.routeStartMarker = new mapboxgl.Marker({ color: '#10B981' })
    this.routeEndMarker = new mapboxgl.Marker({ color: '#EF4444' })
  }

  // initialize
  addButtons () {
    this.fullScreenControl = new FullScreenControl(this.supporterValue)
    this.geoLocateControl = new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserHeading: true
    })
    this.nodeHunterControl = new NodeHunterControl(this.mapboxNodeHunterPopup, this.supporterValue)
    this.routeBuilderControl = new RouteBuilderControl(this.supporterValue)
    this.toggleCityMapControl = new ToggleCityMapControl(mapboxgl, this.supporterValue)
    this.toggleLifeMapControl = new ToggleLifeMapControl(mapboxgl, this.supporterValue, this.userIdValue)
    this.toggleStyleControl = new ToggleStyleControl(this, this.supporterValue)

    this.map.addControl(new mapboxgl.ScaleControl({ unit: this.unitValue }))
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right')

    if (this.userIdValue === '') {
      return
    }

    if (this.map.hasControl(this.toggleLifeMapControl) === false) {
      this.map.addControl(this.toggleLifeMapControl, 'top-left')
    }

    if (this.map.hasControl(this.toggleStyleControl) === false) {
      this.map.addControl(this.toggleStyleControl)
    }

    if (this.map.hasControl(this.routeBuilderControl) === false) {
      this.map.addControl(this.routeBuilderControl, 'top-left')
    }

    if (this.map.hasControl(this.nodeHunterControl) === false) {
      this.map.addControl(this.nodeHunterControl, 'top-left')
    }

    const resetNodeHunterButton = document.getElementById('reset-node-hunter')
    if (resetNodeHunterButton) {
      resetNodeHunterButton.remove()
    }

    if (this.supporterValue === true && this.map.hasControl(this.geoLocateControl) === false) {
      this.map.addControl(this.geoLocateControl, 'top-right')
    }

    if (this.map.hasControl(this.toggleCityMapControl) === false) {
      this.map.addControl(this.toggleCityMapControl, 'top-left')
    }

    if (this.map.hasControl(this.fullScreenControl) === false) {
      this.map.addControl(this.fullScreenControl, 'top-right')
    }
  }

  // initialize
  addRouteBuilderLayer () {
    this.map.addSource('CityStrides-routeBuilder', { type: 'geojson', data: { type: 'LineString', coordinates: [] } })
    this.map.addLayer({
      id: 'CityStrides-routeBuilder',
      source: 'CityStrides-routeBuilder',
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#60a5fa',
        'line-dasharray': [1.5, 1.5],
        'line-width': 6
      }
    }, 'road-label')
    this.map.on('click', (e) => { this.drawRoute(e) })
  }

  adjustClassesLife () {
    this.routeBuildTarget.classList.remove('mt-2')
    this.routeBuildTarget.classList.add('mt-12')
    document.getElementById('map').classList.remove('hidden')
    document.getElementById('map').classList.remove('map-regular')
    document.getElementById('map').classList.remove('map-special')
    document.getElementById('map').classList.add('map-life')
  }

  adjustClassesRegular () {
    this.routeBuildTarget.classList.add('mt-2')
    this.routeBuildTarget.classList.remove('mt-12')
    document.getElementById('map').classList.remove('hidden')
    document.getElementById('map').classList.remove('map-life')
    document.getElementById('map').classList.remove('map-special')
    document.getElementById('map').classList.add('map-regular')
  }

  adjustClassesSpecial () {
    this.routeBuildTarget.classList.add('mt-2')
    this.routeBuildTarget.classList.remove('mt-12')
    document.getElementById('map').classList.remove('hidden')
    document.getElementById('map').classList.remove('map-life')
    document.getElementById('map').classList.remove('map-regular')
    document.getElementById('map').classList.add('map-special')
  }

  // RouteBuilder
  clearRouteMarkers () {
    if (this.routeMileMarkers.length > 0) {
      this.routeMileMarkers.forEach((marker) => {
        marker.remove()
      })
      this.routeMileMarkers = []
    }
  }

  // RouteBuilderComponent
  deleteRoute () {
    if (window.confirm('Are you sure?')) {
      this.routeBuilderDisabledValue = false
      this.routeSaveTarget.classList.add('hidden')
      this.routeShareDivTarget.classList.add('hidden')
      this.routeVisitTarget.classList.add('hidden')
      this.routeNameTarget.classList.add('hidden')
      this.routeNameTarget.value = ''
      this.routeGeojsonValue = ''
      this.routeDistanceValue = ''
      this.routeDirectionsValue = []
      this.routeIdValue = ''
      this.routePreviousManeuversCountValue = 0
      this.routeDistanceTarget.innerHTML = `0 ${this.unitValue === 'metric' ? 'kilometers' : 'miles'}`
      this.routeClicksValue = []
      this.routeStartMarker.remove()
      this.routeEndMarker.remove()
      this.clearRouteMarkers()
      const routeBuilderSource = this.map.getSource('CityStrides-routeBuilder')

      if (this.map.isStyleLoaded() && routeBuilderSource) {
        routeBuilderSource.setData({ type: 'LineString', coordinates: [] })
      }
    }
  }

  // this.map.on('click' listener
  drawRoute (e) {
    if (this.currentlyDrawingValue !== true) {
      return
    }

    const units = this.unitValue === 'metric' ? 'kilometers' : 'miles'
    const interim = this.routeClicksValue
    const lastClick = this.routeClicksValue.slice(-1)[0]
    const currentClick = [e.lngLat.lng, e.lngLat.lat]
    interim.push(currentClick)
    this.routeClicksValue = interim

    if (this.routeClicksValue.length < 2) {
      this.routeStartMarker.setLngLat(currentClick).addTo(this.map)
      return
    } else {
      this.routeNameTarget.classList.remove('hidden')
      this.routeSaveTarget.classList.remove('hidden')
      this.routeShareDivTarget.classList.remove('hidden')
    }

    let parsedRouteGeojsonValue
    if (this.routeGeojsonValue) {
      parsedRouteGeojsonValue = JSON.parse(this.routeGeojsonValue)
    } else {
      parsedRouteGeojsonValue = { type: 'MultiLineString', coordinates: [] }
    }

    if (this.routeBuilderDisabledValue) {
      const firstPoint = parsedRouteGeojsonValue.coordinates.length < 2 ? lastClick : parsedRouteGeojsonValue.coordinates.slice(-1)[0].slice(-1)[0]
      const straightLine = lineString([firstPoint, currentClick])
      parsedRouteGeojsonValue.coordinates.push(straightLine.geometry.coordinates)
      this.drawRouteDataOnMap(parsedRouteGeojsonValue, units)
    } else {
      const params = JSON.stringify({
        locations: [
          { lat: lastClick[1], lon: lastClick[0], type: 'break' },
          { lat: currentClick[1], lon: currentClick[0], type: 'break' }
        ],
        costing: 'pedestrian',
        costing_options: {
          pedestrian: { use_ferry: 0 }
        }
      })
      window.fetch(`https://api.stadiamaps.com/route?json=${params}`).then(response => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json()
        } else {
          throw Error('The was an error in the routing service, please try again')
        }
      }).then(data => {
        this.routePreviousManeuversCountValue = data.trip.legs[0].maneuvers.length
        const routeDirections = this.routeDirectionsValue
        data.trip.legs[0].maneuvers.forEach((item, _index) => {
          routeDirections.push(item.instruction)
        })
        this.routeDirectionsValue = routeDirections

        parsedRouteGeojsonValue.coordinates.push(polylineToGeoJSON(data.trip.legs[0].shape, 6).coordinates)
        this.drawRouteDataOnMap(parsedRouteGeojsonValue, units)
      }).catch(error => {
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: error, type: 'error' }
        })

        if (error.message !== 'The was an error in the routing service, please try again') {
          this.application.handleError(error)
        }
      })
    }
  }

  // drawRoute function right above this
  drawRouteDataOnMap (geojson, units) {
    const newRoute = { type: 'MultiLineString', coordinates: geojson.coordinates }
    this.routeGeojsonValue = JSON.stringify(newRoute)
    this.map.getSource('CityStrides-routeBuilder').setData(newRoute)
    const distance = length(geojson, { units }).toFixed(2)
    this.routeDistanceValue = length(geojson, { units: 'kilometers' }).toFixed(2)
    this.routeDistanceTarget.innerHTML = `${distance} ${units}`
    this.placeRouteMileMarkers(geojson.coordinates, distance, units)
    this.placeDirectionality()
    this.routeEndMarker.setLngLat(geojson.coordinates.slice(-1)[0].slice(-1)[0]).addTo(this.map)
  }

  // RouteBuilderComponent
  fitRoute () {
    if (this.routeGeojsonValue === '') {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: 'Route can be fit to view once it has at least two points', type: 'error' }
      })

      return
    }

    try {
      const coordinates = JSON.parse(this.routeGeojsonValue).coordinates
      const routeBoundingBox = bbox(multiLineString(coordinates))
      this.map.fitBounds(routeBoundingBox, { preload: true, padding: 40 })
    } catch (error) {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    }
  }

  changeMapStyle () {
    const savedLayers = []
    const savedSources = {}

    // Collect all the existing layers & sources
    this.map.getStyle().layers.forEach((layer) => {
      if (!layer.id.includes('CityStrides')) {
        return
      }

      savedSources[layer.source] = this.map.getSource(layer.source).serialize()
      savedLayers.push(layer)
    })

    // Change the style
    // https://github.com/mapbox/mapbox-gl-js/issues/13001
    const mapStyle = document.documentElement.classList.contains('dark') ? this.darkStyleLink : this.regularStyleLink
    let newStyle
    if (this.mapStyleValue === this.regularStyleLink) {
      newStyle = mapStyle
    } else {
      newStyle = this.mapStyleValue
    }

    // https://github.com/mapbox/mapbox-gl-js/issues/13001
    // this.map.setStyle(this.mapStyleValue)
    this.map.setStyle(newStyle)

    // Re-add the layers & sources
    this.map.once('style.load', () => {
      const arrowHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://citystrides.com'
      this.map.loadImage(`${arrowHost}/arrow.png`, (error, image) => {
        if (error) {
          console.log(error)
        } else {
          this.map.addImage('arrow', image)
        }
      })

      Object.entries(savedSources).forEach(([id, source]) => {
        this.map.addSource(id, source)
      })

      savedLayers.forEach((layer) => {
        this.map.addLayer(layer, 'road-label')
      })
    })
  }

  // drawRouteDataOnMap & undoRoute methods in this file
  placeRouteMileMarkers (coordinates, distance, units) {
    this.clearRouteMarkers()

    const collectedCoordinates = coordinates.flat()
    for (let i = 1; i < distance; i++) {
      const routeMileMarker = along({ type: 'LineString', coordinates: collectedCoordinates }, i, { units })
      const el = document.createElement('div')
      el.className = 'w-6 h-6 font-bold text-center border-2 border-purple-900 rounded-full shadow bg-zinc-100 mileMarker'
      el.innerHTML = i
      this.routeMileMarkers.push(new mapboxgl.Marker(el).setLngLat(routeMileMarker.geometry.coordinates).addTo(this.map))
    }
  }

  placeDirectionality () {
    if (this.map.getLayer('CityStrides-routeBuilderDirectionality')) {
      this.map.removeLayer('CityStrides-routeBuilderDirectionality')
    }

    this.map.addLayer({
      id: 'CityStrides-routeBuilderDirectionality',
      type: 'symbol',
      source: 'CityStrides-routeBuilder',
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 1,
        'icon-image': 'arrow',
        'icon-padding': 0,
        'icon-allow-overlap': true,
        'icon-size': 0.3
      }
    }, 'road-label')
  }

  // global function
  removeLayerAndSource (layerName) {
    // I don't know how this can be possible
    // Wait for reports of layers e.g. streets, borders, etc lingering between page loads
    if (typeof this.map === 'undefined') {
      return
    }

    if (this.map.getLayer(layerName)) {
      this.map.removeLayer(layerName)
      this.map.removeSource(layerName)
    }
  }

  // RouteBuilderComponent
  saveRoute (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    let methodType
    let endpoint

    if (typeof this.routeIdValue === 'undefined' || this.routeIdValue === '') {
      methodType = 'POST'
      endpoint = '/routes.json'
    } else {
      methodType = 'PUT'
      endpoint = `/routes/${this.routeIdValue}.json`
    }

    const request = new FetchRequest(methodType, endpoint, {
      body: JSON.stringify({ route: { directions: this.routeDirectionsValue, geojson: this.routeGeojsonValue, name: this.routeNameTarget.value, share: this.routeShareTarget.checked, total_distance: this.routeDistanceValue } })
    })
    request.perform().then(response => {
      if (response.ok || response.statusCode === 422) {
        return response.json
      } else if (response.statusCode === 429) {
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: 'You have made too many requests in a short amount of time', type: 'error' }
        })
        return []
      } else if (response.statusCode === 521) {
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: 'The server is temporarily down, please try again later', type: 'error' }
        })
        return []
      } else {
        throw response
      }
    }).then(data => {
      if (data.error) {
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: `The server returned this error while trying to save the route: ${data.error}`, type: 'error' }
        })
        this.application.handleError(data.error)
      } else {
        this.routeIdValue = data.route_id
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: 'Your Route was successfully saved', type: 'success' }
        })
        this.routeVisitTarget.href = `/routes/${this.routeIdValue}`
        this.routeVisitTarget.classList.remove('hidden')
        this.routeDirectionsValue = []
        this.routePreviousManeuversCountValue = 0
      }
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: '😱 There was an error while trying to save the route', type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  // RouteBuilderComponent
  toggleRouter () {
    this.routeIconRouteTarget.classList.toggle('hidden')
    this.routeIconLineTarget.classList.toggle('hidden')
    this.routeBuilderDisabledValue = !this.routeBuilderDisabledValue
  }

  // RouteBuilderComponent
  undoRoute () {
    const interimClicks = this.routeClicksValue
    interimClicks.pop()
    this.routeClicksValue = interimClicks
    const units = this.unitValue === 'metric' ? 'kilometers' : 'miles'
    this.routeEndMarker.remove()

    if (this.routeClicksValue.length === 0) {
      this.routeStartMarker.remove()
      this.routeSaveTarget.classList.add('hidden')
      this.routeShareDivTarget.classList.add('hidden')
      this.routeNameTarget.classList.add('hidden')
      this.routeDirectionsValue = []
      return
    } else if (this.routeClicksValue.length < 2) {
      this.routeSaveTarget.classList.add('hidden')
      this.routeShareDivTarget.classList.add('hidden')
      this.routeNameTarget.classList.add('hidden')
      this.routeGeojsonValue = []
      this.routeDistanceTarget.innerHTML = `0 ${units}`
      this.routeDirectionsValue = []
      this.map.getSource('CityStrides-routeBuilder').setData({ type: 'LineString', coordinates: [] })
      this.clearRouteMarkers()
      return
    }

    const interimDirections = this.routeDirectionsValue
    for (let i = 0; i < this.routePreviousManeuversCountValue; i++) {
      interimDirections.pop()
    }
    this.routeDirectionsValue = interimDirections

    const interimGeojson = JSON.parse(this.routeGeojsonValue)
    interimGeojson.coordinates.pop()
    const distance = length(interimGeojson, { units }).toFixed(2)
    this.routeDistanceValue = length(interimGeojson, { units: 'kilometers' }).toFixed(2)
    this.routeDistanceTarget.innerHTML = `${distance} ${units}`
    this.routeGeojsonValue = JSON.stringify(interimGeojson)
    this.map.getSource('CityStrides-routeBuilder').setData(interimGeojson)
    this.placeRouteMileMarkers(interimGeojson.coordinates, distance, units)
    this.placeDirectionality()
    this.routeEndMarker.setLngLat(interimGeojson.coordinates.slice(-1)[0].slice(-1)[0]).addTo(this.map)
  }

  // Any page with a list of cities
  removeAllCities () {
    this.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-cityBorder')) {
        this.removeLayerAndSource(layer.id)
      }
    })
  }

  // Any page with a list of streets
  removeAllStreets () {
    this.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-markerCollection')) {
        this.removeLayerAndSource(layer.id)
      }
    })
  }

  // Any page with a list of users
  removeAllUsers () {
    this.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-userCollection')) {
        this.removeLayerAndSource(layer.id)
      }
    })
  }

  // RouteBuilder & on initial load
  currentlyDrawingValueChanged () {
    // this hack handles the fact that ValueChanged is always called on load
    if (typeof this.map === 'undefined') {
      return
    }

    if (this.currentlyDrawingValue === true) {
      this.map.getCanvas().style.cursor = 'crosshair'
      this.routeBuildTarget.classList.remove('hidden')
      const bookmarker = document.getElementById('bookmarker')
      if (bookmarker) {
        bookmarker.classList.add('hidden')
      }
    } else {
      this.map.getCanvas().style.cursor = 'grab'
      this.routeBuildTarget.classList.add('hidden')
      this.routeVisitTarget.classList.add('hidden')
      this.routeSaveTarget.classList.add('hidden')
      this.routeShareDivTarget.classList.add('hidden')
      this.routeNameTarget.classList.add('hidden')
      this.routeNameTarget.value = ''
      this.routeGeojsonValue = ''
      this.routeDistanceValue = ''
      this.routeIdValue = ''
      this.routeDistanceTarget.innerHTML = `0 ${this.unitValue === 'metric' ? 'kilometers' : 'miles'}`
      this.routeClicksValue = []
      this.routeStartMarker.remove()
      this.routeEndMarker.remove()
      this.clearRouteMarkers()

      const bookmarker = document.getElementById('bookmarker')
      if (document.querySelectorAll('[data-controller="mapless"]').length === 0 && bookmarker) {
        bookmarker.classList.remove('hidden')
      }

      try {
        this.map.getSource('CityStrides-routeBuilder').setData({ type: 'LineString', coordinates: [] })
      } catch {
      }
    }
  }
}

StreamActions.streamed_markers = function () {
  const container = document.getElementById(this.getAttribute('container'))
  container.dataset.mapFeaturedChallengeStreamedMarkersValue = this.getAttribute('coordinates')
}

import { Controller } from '@hotwired/stimulus'
import bbox from '@turf/bbox'
import TimeFilterControl from '../src/mapbox/time_filter.js'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    center: Array,
    cityGeoJsonUrl: String,
    supporter: Boolean,
    userId: String,
    viewingSelf: Boolean
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    this.timeFilterControl = new TimeFilterControl(this.supporterValue, this.userIdValue, this.viewingSelfValue)
    outlet.adjustClassesRegular()

    if (outlet.mapIsInitialized) {
      this.addCity()
      this.addSupporterControls()
      this.addLifeMap()
    } else {
      outlet.map.once('style.load', () => {
        this.addCity()
        this.addSupporterControls()
        this.addLifeMap()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    outlet.removeLayerAndSource('CityStrides-cityBorder')
    outlet.removeAllStreets()
    outlet.removeAllUsers()

    if (this.viewingSelfValue === true) {
      if (outlet.map.getLayer('CityStrides-selfLifeMap')) {
        outlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'none')
        outlet.toggleLifeMapControl.buttonUnhighlight()
      }
    } else {
      outlet.removeLayerAndSource(`CityStrides-lifeMap${this.userIdValue}`)
    }

    if (outlet.map.hasControl(this.timeFilterControl) === true) {
      outlet.map.removeControl(this.timeFilterControl)
    }
  }

  addCity () {
    this.mapOutlet.map.resize()
    // semi-duplicate code in cities_controller.js (it specifies city ID)
    window.fetch(this.cityGeoJsonUrlValue, { credentials: 'same-origin' }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json()
      } else {
        throw Error('😱 There was an error when retrieving the city border')
      }
    }).then(data => {
      const boundingBox = bbox(data)
      this.mapOutlet.removeLayerAndSource('CityStrides-cityBorder')
      this.mapOutlet.map.addSource('CityStrides-cityBorder', { type: 'geojson', data })
      this.mapOutlet.map.addLayer({
        id: 'CityStrides-cityBorder',
        type: 'line',
        source: 'CityStrides-cityBorder',
        paint: {
          'line-color': '#60a5fa',
          'line-width': 7
        }
      }, this.mapOutlet.map.getLayer('CityStrides-selfLifeMap') ? 'CityStrides-selfLifeMap' : 'road-label')
      this.mapOutlet.map.fitBounds(boundingBox, { animate: false, preload: true, padding: 40 })
    }).catch(error => {
      this.mapOutlet.map.jumpTo({ center: this.centerValue, zoom: 10, preload: true })
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })

    // new mapboxgl.Popup({ className: `cityPopup${id}` }).setLngLat(this.centerValue).setHTML(`${name}, ${regionName}`).addTo(this.mapOutlet.map)
  }

  addSupporterControls () {
    if (this.mapOutlet.map.hasControl(this.timeFilterControl) === false) {
      this.mapOutlet.map.addControl(this.timeFilterControl, 'top-left')
    }
  }

  // duplicate code in map_city_controller.js map_lifemap_controller.js and toggle_lifemap.js
  addLifeMap () {
    if (this.viewingSelfValue === true) {
      if (this.mapOutlet.map.getLayer('CityStrides-selfLifeMap')) {
        this.mapOutlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'visible')
        this.mapOutlet.toggleLifeMapControl.buttonHighlight()
      }
    } else {
      if (this.mapOutlet.map.getLayer('CityStrides-selfLifeMap')) {
        this.mapOutlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'none')
        this.mapOutlet.toggleLifeMapControl.buttonUnhighlight()
      }

      this.addLifeMapLayer()
    }
  }

  addLifeMapLayer () {
    const tileHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://citystrides.com'
    this.mapOutlet.map.addSource(`CityStrides-lifeMap${this.userIdValue}`, {
      type: 'vector',
      maxzoom: 15,
      tiles: [`${tileHost}/users/${this.userIdValue}/tiles/{z}/{x}/{y}`]
    })
    this.mapOutlet.map.addLayer({
      id: `CityStrides-lifeMap${this.userIdValue}`,
      type: 'line',
      source: `CityStrides-lifeMap${this.userIdValue}`,
      'source-layer': 'activities',
      layout: {
        visibility: 'visible',
        'line-join': 'round',
        'line-cap': 'round',
        'line-sort-key': ['get', 'start_time']
      },
      paint: {
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hide'], false],
          0,
          1
        ],
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#4C1D95',
          '#8B5CF6'
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          4,
          2
        ]
      }
    }, 'road-label')
  }
}
